<template>
  <div>
    <section class="contact-page policy-content section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-justify">
            <h2 class="text-left mb-3 mt-4">TEKA Delivery Policy:</h2>
            <p> <b>Delivery Policy :</b> Customer shipping address and Pin-code will be verified by TEKA E-Commerce in their Database before you proceed to pay for your purchase.</p>
            <p>If the service/delivery address is not in the list of the areas covered by TEKA or its Seller/Service Provider/Logistic Partners, Teka will not be held responsible/liable.  In such cases Customer can give alternative address to fulfill the order.  </p>
            <p>TEKA will try to fulfill multiple orders together if it is pertaining to single Seller/Service Provider. If product specification and logistical issues raises then it may not be possible. </p>
            <p>Customer ordered Products shall be delivered by the Logistics Partner at the address given by the customer while ordering the Products and TEKA E-Commerce shall be not be held responsible if the Customer shares incorrect address.</p>
            <h5>EOTD: Estimated On Time Delivery:</h5>
            <p>In general the Sellers procure the products from the manufacturers/ wholesalers and dispatches the items as per the time frame which reflects at the product section.  Holidays and Sundays will be excluded for shipment.</p>
            <p>Estimated on Time Delivery depends on Natural Calamities, Law & Order situation, Climatic conditions  etc. which are beyond the control of Human Nature.</p>
            <p>All the products purchased on TEKA will have no hidden charges.  The listed price are final and all-inclusive except Logistics charge where applicable.</p>
            <p>Service Areas are based on the Seller shipping location, legal restriction, and availability of Logistic Partner in the areas mentioned for delivery by the Customer.  </p>
            
          </div>
        </div>
      </div>
    </section>
    <!-- Section ends -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      title1: "teka delivery policy",
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  
};
</script>
<style scoped>
.policy-content h2 {
  color: #533333;
  font-weight: 600;
}
</style>
