var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "contact-page policy-content section-b-space"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 text-justify"
  }, [_c('h2', {
    staticClass: "text-left mb-3 mt-4"
  }, [_vm._v("TEKA Delivery Policy:")]), _c('p', [_c('b', [_vm._v("Delivery Policy :")]), _vm._v(" Customer shipping address and Pin-code will be verified by TEKA E-Commerce in their Database before you proceed to pay for your purchase.")]), _c('p', [_vm._v("If the service/delivery address is not in the list of the areas covered by TEKA or its Seller/Service Provider/Logistic Partners, Teka will not be held responsible/liable. In such cases Customer can give alternative address to fulfill the order. ")]), _c('p', [_vm._v("TEKA will try to fulfill multiple orders together if it is pertaining to single Seller/Service Provider. If product specification and logistical issues raises then it may not be possible. ")]), _c('p', [_vm._v("Customer ordered Products shall be delivered by the Logistics Partner at the address given by the customer while ordering the Products and TEKA E-Commerce shall be not be held responsible if the Customer shares incorrect address.")]), _c('h5', [_vm._v("EOTD: Estimated On Time Delivery:")]), _c('p', [_vm._v("In general the Sellers procure the products from the manufacturers/ wholesalers and dispatches the items as per the time frame which reflects at the product section. Holidays and Sundays will be excluded for shipment.")]), _c('p', [_vm._v("Estimated on Time Delivery depends on Natural Calamities, Law & Order situation, Climatic conditions etc. which are beyond the control of Human Nature.")]), _c('p', [_vm._v("All the products purchased on TEKA will have no hidden charges. The listed price are final and all-inclusive except Logistics charge where applicable.")]), _c('p', [_vm._v("Service Areas are based on the Seller shipping location, legal restriction, and availability of Logistic Partner in the areas mentioned for delivery by the Customer. ")])])])])])]);
}]

export { render, staticRenderFns }